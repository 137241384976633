'use strict';

// ANSI codes for debug messages https://telepathy.freedesktop.org/doc/telepathy-glib/telepathy-glib-debug-ansi.html
const Color = {
  Reset: "\x1b[0m",
  Bright: "\x1b[1m",
  Dim: "\x1b[2m",
  Underscore: "\x1b[4m",
  // Blink: "\x1b[5m",
  // Reverse: "\x1b[7m",
  // Hidden: "\x1b[8m",
  
  // FgBlack: "\x1b[30m",
  FgRed: "\x1b[31m",
  FgGreen: "\x1b[32m",
  FgYellow: "\x1b[33m",
  FgBlue: "\x1b[34m",
  FgMagenta: "\x1b[35m",
  FgCyan: "\x1b[36m",
  FgWhite: "\x1b[37m",
  
  // BgBlack: "\x1b[40m",
  // BgRed: "\x1b[41m",
  // BgGreen: "\x1b[42m",
  // BgYellow: "\x1b[43m",
  // BgBlue: "\x1b[44m",
  // BgMagenta: "\x1b[45m",
  // BgCyan: "\x1b[46m",
  // BgWhite: "\x1b[47m"
}


module.exports = {
      
  consoleInfo (message, data) {
    if (data) {
      console.info(`${Color.FgWhite}${message}${Color.Reset}`, data);
    } else {
      console.info(`${Color.FgWhite}${message}${Color.Reset}`);
    }
  },
  
  consoleLog (message, data) {
    if (data) {
      console.log(`${Color.FgWhite}${message}${Color.Reset}`, data);
    } else {
      console.log(`${Color.FgWhite}${message}${Color.Reset}`);
    }
  },
      
  consoleError (message, data) {
    if (data) {
      console.error(`${Color.FgRed}${message}${Color.Reset}`, data);
    } else {
      console.error(`${Color.FgRed}${message}${Color.Reset}`);
    }
  },
      
  consoleWarn (message, data) {
    if (data) {
      console.warn(`${Color.FgYellow}${message}${Color.Reset}`, data);
    } else {
      console.warn(`${Color.FgYellow}${message}${Color.Reset}`);
    }
  },
    
  consoleTime (message, data) {
    if (data) {
      console.info(`${new Date().getTime()}: ${Color.FgWhite}${message}${Color.Reset}`, data);
    } else {
      console.info(`${new Date().getTime()}: ${Color.FgWhite}${message}${Color.Reset}`);
    }
    
    // console.time('key');
    // console.timeEnd('key'); //-->prints time between keys!
  },
    
  consoleTable (data) {
    console.table(data);
  },
  
  consoleSeparate (message, SeparatorChar = '-') {
    console.info(`${' '.repeat(100)}`);
    console.info(`${Color.FgWhite}${SeparatorChar.repeat(10)} ${Color.Underscore}${message}${Color.Reset} ${SeparatorChar.repeat(10)}${Color.Reset}`);
  },

  
}


/*
 *  Examples:
 
    const {consoleWarn, consoleInfo, consoleLog, consoleSeparate, consoleError, consoleTime, consoleTable}  = require("./console");
    
    consoleSeparate('console options:')
    consoleInfo('testing');
    consoleLog('testing');
    consoleWarn('testing');
    consoleError('testing');
    consoleTime('testing');
    consoleTable(data);
*/