const MilliSeconds        = 1000;
const MinuteMilliSeconds  = 60000;
const HourMilliSeconds    = 3600000;
const DayMilliSeconds     = 86400000;
const WeekDaysUC          = Array("SU", "MO","TU","WE","TH","FR","SA");
const WeekDaysLC          = Array("Su", "Mo","Tu","We","Th","Fr","Sa");
const WeekDaysLong        = Array("Sunday", "Monday","Tuesday","Wednesday","Thursday","Friday","Saturday");
const MonthName           = Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
const MonthNameShort      = Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");

const { consoleWarn }     = require("./console");
    
module.exports = {
  
  DDMMYYYY (d = new Date()){
    return module.exports.DoubleDigits(d.getDate()) + "-" + module.exports.DoubleDigits(d.getMonth() +1) +  "-" + d.getFullYear();
  },
  
  HHii (d = new Date()) {
    return module.exports.DoubleDigits(d.getHours()) + ":" + module.exports.DoubleDigits(d.getMinutes());
  },
  
  HHiiss (d = new Date()) {
    return module.exports.DoubleDigits(d.getHours()) + ":" + module.exports.DoubleDigits(d.getMinutes()) + ":" + module.exports.DoubleDigits(d.getSeconds());
  },
  
  DDMMYYYY_HHii (d = new Date()) {
    return module.exports.DDMMYYYY(d) + " " + module.exports.HHii(d);
  },
  
  YYYYMMDD (d = new Date()) {
    return d.getFullYear() + "-" + module.exports.DoubleDigits(d.getMonth() +1) +  "-" + module.exports.DoubleDigits(d.getDate());
  },
  
  YYYYMMDD_HHii (d = new Date()) {
    return module.exports.YYYYMMDD(d) + " " + module.exports.HHii(d);
  },
  
  YYYYMMDD_HHiiss (d = new Date()) {
    return module.exports.YYYYMMDD(d) + " " + module.exports.HHiiss(d);
  },
  
  DayMonthYYYY (d = new Date()) {
    return module.exports.DayNameLC(d.getDay()) + " " + d.getDate() + " " + module.exports.MonthName(d.getMonth(), true) +  " " + d.getFullYear();
  },
  
  DayMonthYYYY_HHii (d = new Date()) {
    return module.exports.DayMonthYYYY(d) + " " + module.exports.HHii(d);
  },
  
  DayMonthYYYY_HHiiss (d = new Date()) {
    return module.exports.DayMonthYYYY(d) + " " + module.exports.HHiiss(d);
  },
  
  DayNr (d = new Date()) {
    return d.getDay();
  },
  MonthNr (d = new Date()) {
    return d.getMonth()+1;
  },
  YearNr (d = new Date()) {
    return d.getFullYear();
  },
  
  DateDayName (d = new Date(), failVal = "") {
    return module.exports.DayNameLC(d.getDay(), failVal);
  },
  
  DateDayNameLong (d = new Date(), failVal = "") {
    return module.exports.DayNameLong(d.getDay(), failVal);
  },
  
  DayName (DayNr, failVal = "") {
    if (typeof(WeekDaysUC[DayNr]) === "undefined") {
      return failVal;
    }
    return WeekDaysUC[DayNr];
  },
  
  DayNameLong (DayNr, failVal = "-") {
    if (typeof(WeekDaysLong[DayNr]) === "undefined") {
      return failVal;
    }
    return WeekDaysLong[DayNr];
  },
  
  DayNameLC (DayNr, failVal = "-") {
    if (typeof(WeekDaysLC[DayNr]) === "undefined") {
      return failVal;
    }
    return WeekDaysLC[DayNr];
  },
  
  DateMonthName (d = new Date(), useShortName = false, failVal = "-") {
    return module.exports.MonthName(d.getMonth(), useShortName, failVal);
  },
  
  MonthName (MonthNr, useShortName = false, failVal = "-") {
    if (typeof(MonthNameShort[MonthNr]) === "undefined") {
      return failVal;
    }
    if (useShortName) {
      return MonthNameShort[MonthNr];
    }    
    return MonthName[MonthNr];
  },
  
  isInt (val, failVal = false) {
    if ( val === null || val === false || typeof(val) !== "number" ) {
      return failVal;
    }
    return true;
  },
  
  isPositiveInt (val, failVal = 0) {
    if (module.exports.isInt(val) !== true) {
      return failVal;
    }
    if (parseInt(val) < 0) {
      return failVal;
    }
    return true;
  },
  
  Time (d = new Date()) {
    return d.getTime();
  },
  
  TimeDiff (from, till = module.exports.Time(), RoundToSeconds = true, failVal = 0) {
    if (module.exports.isInt(from) !== true || module.exports.isInt(till) !== true) {
      return failVal;
    }
    if (RoundToSeconds !== true) {
      //keep milliseconds variations between calculations
      return till - from;  
    }
    
    return (Math.round(till/1000) - Math.round(from/1000))  * 1000;
  },
  
  TimeToString (TimeVal) {
    if (TimeVal > DayMilliSeconds) {
      const d = Math.ceil(TimeVal / DayMilliSeconds);
      if (d > 1) {
        return d + " days";  
      }
      return d + " day";
    }
    
    if (TimeVal >= HourMilliSeconds) {
      const h = Math.ceil(TimeVal / HourMilliSeconds);
      if (h > 1) {
        return h + " hours";  
      }
      return h + " hour";  
    } 
    
    if (TimeVal >= MinuteMilliSeconds) {
      const m = Math.ceil(TimeVal / MinuteMilliSeconds);
      if (m > 1 ) {
        return m + " minutes";  
      }
      return m + " minute";
    }
    
    const s = Math.ceil(TimeVal / MilliSeconds);
    if (s > 1) {
      return s + " seconds";
    } else if (s === 1) {
      return s + " second";
    }
    
    return 0;
  },
  
  TimePassed (from, till = module.exports.Time(), failVal = 0) {
    const diff = module.exports.TimeDiff(from, till);
    if (diff === false) {
      return failVal;
    }
    if (diff <=0) {
      return 0;
    }
    return module.exports.TimeToString(diff);
  },
  
  WeekNr (d = new Date()) {
    const TmpDate = new Date(d);
    // Create a copy of this date object, otherwise the original date can change!
    //var CalcDate  = new Date(2019,2,11,0,0,0);

    // ISO week date weeks start on monday
    // so correct the day number
    var DayNr   = (TmpDate.getDay() + 6) % 7;

    // Set the CalcDate to the thursday of this week so the
    // CalcDate date is in the right year
    TmpDate.setDate(TmpDate.getDate() - DayNr + 3);

    // ISO 8601 states that week 1 is the week
    // with january 4th in it
    var Jan4    = new Date(TmpDate.getFullYear(), 0, 4);

    // Number of days between CalcDate date and january 4th
    var DayDiff = (TmpDate - Jan4) / 86400000;

    // Calculate week number: Week 1 (january 4th) plus the
    // number of weeks between CalcDate date and january 4th
    var WeekNr = 1 + Math.ceil(DayDiff / 7);

    //console.log("Week nr: " + CalcDate.toISOString() + "--->" + WeekNr);
    return WeekNr;
  },
  
  FirstWeekDayDateObj (d = new Date()) {
    return new Date(d - ((d.getDay() -1) * 86400000));
  },
  FirstWeekDay (d = new Date()) {
    return module.exports.FirstWeekDayDateObj(d).getDate();
  },
  
  LastWeekDayDateObj (d = new Date()) {
    return new Date(d - ((d.getDay() -7) * 86400000));
  },
  LastWeekDay (d = new Date()) {
    return module.exports.LastWeekDayDateObj(d).getDate();
  },
  WeekLabel (d = new Date()) {
    const start = module.exports.FirstWeekDayDateObj(d);
    const end = module.exports.LastWeekDayDateObj(d);
    
    if (start.getMonth() === end.getMonth()) {
      return start.getDate() + " - " + end.getDate()  + " " + module.exports.MonthName(end.getMonth(), true);
    } 
    return start.getDate() + " " + module.exports.MonthName(start.getMonth(), true) + " - " + end.getDate()  + " " + module.exports.MonthName(end.getMonth(), true);
  },
  
  isDatesSameDay (Date1, Date2) {
    if (Date1.getFullYear() === Date2.getFullYear() &&
        Date1.getMonth() === Date2.getMonth() &&
        Date1.getDate() === Date2.getDate()){
        return true;
    } 
    
    return false;
  },
  
  isWithinSameOneDay (Date1, Date2) {
    const diff = module.exports.TimeDiff(Date1.getTime(), Date2.getTime(), true, false);
    if (diff === false || Math.abs(diff) > DayMilliSeconds ) {
      return false;
    }
    return true;
  },
    
  
  DoubleDigits (number) {
    if (parseInt(number) < 10) {
      return "0" + number;
    }
    return number;
  },
  
  TickCount (now = new Date()) {
    return now.getTime();
  },
  
 
  
  
  DateInfo (d) {

    //const ThaDate        = new Date();
    const CurMonthNr       = d.getMonth();
    const CurWeekDayNr     = d.getDay();

    let DateInfo = {
      CurDate             : d,
      CurYear             : d.getFullYear(),
      CurMonthNr          : d.getMonth(),
      CurDayNr            : d.getDate(),
      CurWeekDayNr        : d.getDay(),
      Time                : d.getTime(),

      //Calc
      CurWeekNr           : module.exports.WeekNr(d),
      WeekFirstDay        : module.exports.FirstWeekDayDateObj(d),
      WeekLastDay         : module.exports.LastWeekDayDateObj(d),
      CurMonthName        : module.exports.MonthName(CurMonthNr, false),
      CurMonthNameShort   : module.exports.MonthName(CurMonthNr, true),
      CurWeekDayName      : module.exports.DayName(CurWeekDayNr),
      
      WeekLabel           : module.exports.WeekLabel(d),
    };

    
    return DateInfo;
  },
  
  
  
  
  
  isValidDate (d, MinYear = null, failVal = false) {
    try {
      const isDateInstance = d instanceof Date && !isNaN(d.getTime());
      if (!isDateInstance) {
        return failVal;
      }
      
      if (MinYear !== null && d.getFullYear() < MinYear) {
        return failVal;
      }
      
      return true;
    } catch (err) { 
      console.info(`Error isValidDate:`, err.message); 
      return failVal;
    }
  },
  
  
  
  isValidDateTimeISO (DateTimeStr, MinYear = null, failVal = false) {
    //example: 2021-05-23T12:03:09.000Z
    try {
      if (typeof(DateTimeStr) !== "string") return failVal;
      if (DateTimeStr.length <= 10) return failVal;
      
      const d = new Date(DateTimeStr);
      const isDateInstance = d instanceof Date && !isNaN(d.getTime());
      if (!isDateInstance) {
        return failVal;
      }
      
      if (MinYear !== null && d.getFullYear() < MinYear) {
        return failVal;
      }
      
      return true;
    } catch (err) { 
      console.info(`Error isValidDateTimeISO:`, err.message); 
      return failVal;
    }
  },
  
  isDateString_YYYYDDMM (val, failVal = false) {
    //example: 2021-05-23T12:03:09.000Z
    if (typeof(val) !== "string") return failVal;
    if (val.length < 8) return failVal;
    
    var date_regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/ ;
    if (!date_regex.test(val)) {
      return failVal;
    }
    
    return true;
  },
  
  
  
  
  
  
  
  
  /*
   * OLD, TO DEL, BACKWARDS COMPATIBILITY My Date FUNCTIONS....
   */
  WarnOldFunction (old, replacewith) {
    consoleWarn(`Warning, using old function ${old}, replace with ${replacewith}`);
  },
  
  get_doubleDigits (number) {
    module.exports.WarnOldFunction('get_doubleDigits', 'DoubleDigits');
    return module.exports.DoubleDigits(number);
  },
  
  get_DateDDMMYYYY (d = new Date()) {
    module.exports.WarnOldFunction('get_DateDDMMYYYY', 'DDMMYYYY');
    return module.exports.DDMMYYYY(d);
  },
  
  get_DateDDMMYYYY_HH_ii (d = new Date()) {
    module.exports.WarnOldFunction('get_DateDDMMYYYY_HH_ii', 'DDMMYYYY_HHii');
    return module.exports.DDMMYYYY_HHii(d)
  },
  
  get_DateHH_ii (d = new Date()) {
    module.exports.WarnOldFunction('get_DateHH_ii', 'HHii');
    return module.exports.HHii(d);
  },
  
  get_DateYYYYMMDD (d = new Date()) {
    module.exports.WarnOldFunction('get_DateYYYYMMDD', 'YYYYMMDD');
    return module.exports.YYYYMMDD(d);
  },
  
  get_DateDayMonthYYY (d = new Date()) {
    module.exports.WarnOldFunction('get_DateDayMonthYYY', 'DayMonthYYYY');
    return module.exports.DayMonthYYYY(d);
  },
      
  get_Date_DayName (DayNr) {
    module.exports.WarnOldFunction('get_Date_DayName', 'DayName');
    return module.exports.DayName(DayNr)
  },
  
  get_Date_DayNameLC (DayNr) {    
    module.exports.WarnOldFunction('get_Date_DayNameLC', 'DayNameLC');
    return module.exports.DayNameLC(DayNr);
  },
  
  get_Date_MonthName (MonthNr, useShort) {
    module.exports.WarnOldFunction('get_Date_MonthName', 'MonthName');
    return module.exports.MonthName(MonthNr, useShort);
  },
  
  Calc_TimePassedMsg (TimeStamp) {
    module.exports.WarnOldFunction('Calc_TimePassedMsg', 'TimeDiff & TimeToString');
    return module.exports.TimePassed(TimeStamp);
  },
  
  Calc_TimeReadableString (TimeInt) {
    module.exports.WarnOldFunction('Calc_TimeReadableString', 'TimeToString');
    return module.exports.TimeToString(TimeInt);
  },
  
  CalcWeekNr (CalcDate) {
    module.exports.WarnOldFunction('CalcWeekNr', 'WeekNr');
    return module.exports.WeekNr(CalcDate);
  },
  
  GetTickCount () {
    module.exports.WarnOldFunction('GetTickCount', 'TickCount');
    return module.exports.TickCount();
  },
  
  
  get_DateInfo (d) {
    module.exports.WarnOldFunction('get_DateInfo', 'DateInfo');
    return module.exports.DateInfo(d);
  },
} 